<!--
 * @Author: 刘格优
 * @Date: 2020-03-19 15:54:22
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-05-14 11:01:22
 -->
<template>
  <div class="set_content">
    <div class="top">
      <p>职业认证</p>
      <div>
        <img src="@/assets/nh/zyrz.png"
             alt="">
        <span>尊敬的用户，您还没有保险从业执业登记，为了保证您能合法合规地开展保险经纪业务，如需我司为您做保险经纪从业人员执业登记，请完成执业登记申请信息填写。</span>
      </div>
    </div>
    <div class="wd_change">
      <div class="change_item">
        <div class="ci_left">
          <span>姓名</span>
        </div>
        <div class="ci_right">
          <input v-model="name"
                 type="text"
                 placeholder="请输入您的真实姓名" />
        </div>
      </div>
      <div class="change_item">
        <div class="ci_left">
          <span>身份证号</span>
        </div>
        <div class="ci_right">
          <input v-model="cardnum"
                 type="text"
                 placeholder="请输入您的真实身份证号" />
        </div>
      </div>
    </div>
    <div class="btn">
      <div class="quitBtn"
           @click="submit">确定</div>
    </div>
    <div class="xyword">
      <p class="frontpic">
        <img src="@/assets/nh/uncheck.png"
             alt=""
             v-if="!isxz"
             @click="isxz = !isxz">
        <img src="@/assets/nh/check.png"
             alt=""
             v-else
             @click="isxz = !isxz">
        <span class="tyjs"
              @click="isxz = !isxz">我已阅读并接受</span>

        <span @click="toshow()">《核查职业资质授权书》</span>
      </p>
    </div>
    <md-landscape v-model="showPic"
                  class="jfbox">
      <div class="s-box">
        <div class="righttop">
          <img src="@/assets/nh/zpa.png"
               alt="">
        </div>

        <div class="topimg">
          <img src="@/assets/nh/zpc.png"
               alt=""
               v-if="status == '0'">
          <img src="@/assets/nh/zpd.png"
               alt=""
               v-if="status == '1'">
          <img src="@/assets/nh/zpb.png"
               alt=""
               v-if="status == '2'">
        </div>
        <p class="lxkf"
           v-if="status == '0'">完善资料</p>
        <p class="lxkf"
           v-if="status == '1'">签名确认</p>
        <p class="lxkf"
           v-if="status == '2'">联系客服</p>
        <div class="lxdesc"
             v-if="status == '0'">
          <p>根据监管相关规定，您需先完成职业认证登记，</p>
          <p>方可进行互联网保险业务。点击下方去完善</p>
          <p>完成执业登记，一起玩赚汇立天下吧！</p>
        </div>
        <div class="lxdesc"
             v-if="status == '1'">
          <p>尊敬的用户，您已是南华保险经纪线下营销员，确认签名即刻玩赚汇立天下！</p>
        </div>
        <p class="lxdesc"
           v-if="status == '2'">尊敬的用户，我们查询到您在其他公司已有执业资格编号，点击联系客服找解决方案吧！</p>
        <div class="tjbtn"
             v-if="status == '0'"
             @click="toshowtype()">
          去完善
        </div>
        <div class="tjbtn"
             v-if="status == '1'"
             @click="toshowtype()">
          去签名
        </div>
        <div class="tjbtn"
             v-if="status == '2'"
             @click="toshowtype()">
          找客服
        </div>
      </div>
    </md-landscape>
  </div>
</template>

<script>
import { checkEmpCert } from '@/api/agent/agent'
import pdf from 'vue-pdf'
import { Toast } from 'mand-mobile'
export default {
  components: {
    pdf
  },
  data () {
    return {
      name: '',
      cardnum: '',
      status: '',
      isxz: false,
      filepath: '',
      pageCount: 0,
      numPages: 0,
      showPic: false
    }
  },
  mounted () {
    $(document).on('focusout', function () {
      // 软键盘收起的事件处理
      window.scroll(0, 0)
    })
  },

  methods: {
    submit () {
      const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (!regIdCard.test(this.cardnum)) {
        Toast.info('身份证号码必须是18位有效字符')
        return
      }
      if (!this.isxz) {
        Toast.info('请勾选同意相关协议')
        return
      }
      checkEmpCert({ name: this.name, cardnum: this.cardnum }).then((res) => {
        this.status = res.data.data.status
        // this.status = '1'
        this.showPic = true
      })
    },
    toshowtype () {
      if (this.status == '0') {
        this.$router.push(`/dzincome?cardno=${this.cardnum}&empname=${this.name}`)
      } else if (this.status == '1') {
        this.$router.push(`/signature?cardno=${this.cardnum}`)
      } else if (this.status == '2') {
        this.$router.push('/customerserver')
      }
    },
    toshow () {
      this.$router.push('/hczyzi')
    }
  }
}

</script>
<style scoped lang="stylus">
.jfbox {
  /deep/ .md-landscape-content {
    height: 90vw;
    width: 80vw;
  }

  /deep/ .md-landscape-close {
    position: absolute !important;
    top: -0.2rem !important;
    right: 4vw !important;
    font-size: 0.7rem !important;
  }

  .s-box {
    height: 90vw;
    width: 80vw;
    background-color: #ffffff;
    border-radius: 0.4rem;
    overflow: hidden;
    text-align: center;
    position: relative;

    .righttop {
      width: 100%;
      text-align: right;

      img {
        width: 35vw;
      }
    }

    .topimg {
      width: 100%;
      position: absolute;
      top: 8vw;

      img {
        width: 20vw;
      }
    }

    .lxkf {
      font-size: 0.5rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(56, 56, 56, 1);
      margin-top: 0.3rem;
    }

    .lxdesc {
      width: 100%;
      text-align: center;
      padding: 0.5rem 5%;

      p {
        font-size: 0.33rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #9b9b9b;
        line-height: 0.57rem;
      }
    }

    .tjbtn {
      width: 4rem;
      height: 1.2rem;
      line-height: 1.3rem;
      background-color: #fb6821;
      color: #fff;
      display: inline-block;
      border-radius: 2rem;
      margin-top: 0.1rem;
    }
  }
}

.set_content {
  background-color: #F6F6F6;
  position: relative;
  height: 100%;

  .top {
    background-color: #FFFEFF;
    padding: 1rem 0.5rem 0.5rem;
    margin-bottom: 0.3rem;

    p {
      font-size: 0.5rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    div {
      img {
        width: 0.4rem;
        height: 0.4rem;
      }

      span {
        font-size: 0.35rem;
        color: #979797;
        line-height: 0.65rem;
        position: relative;
        bottom: 0.1rem;
        padding-left: 0.2rem;
      }
    }
  }

  .wd_change {
    background-color: #FFFEFF;

    .change_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #F6F6F6;
      padding: 50px 60px;

      .ci_left {
        span {
          font-size: 39px;
          font-family: PingFang SC;
          // font-weight: 500;
          color: rgba(56, 56, 56, 1);
        }
      }

      .ci_right {
        input {
          overflow: hidden;
          font-size: 36px;
          border: none;
          text-align: right;
          outline: none;
        }

        .seePwd {
          padding-left: 20px;
        }
      }
    }
  }

  .btn {
    text-align: center;
    padding-top: 100px;

    .quitBtn {
      padding: 30px 30px;
      margin: 80px auto;
      width: 90%;
      background-color: color-primary;
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      border-radius: 2rem;
    }
  }
}

/* 协议 */
.xyword {
  display: flex;
  justify-content: center;
  // margin-top: 150px;
  padding: 0 5%;
  // position: absolute;
  // width: 100%;
  // bottom: 2rem;
  margin-top: 45%;

  p {
    font-size: 34px;
    font-family: PingFang SC;
  }

  .frontpic {
    img {
      width: 34px;
      height: 34px;
    }

    .tyjs {
      color: #383838;
      font-size: 34px;
      font-family: PingFang SC;
    }

    span {
      font-size: 34px;
      color: #108EE9;
      font-family: PingFang SC;
      margin: 0 2px;
    }
  }
}

.closepdf {
  position: fixed !important;
  /* right: 0.25rem; */
  /* top: 0.25rem; */
  bottom: 0px !important;
  margin: 0 auto !important;
  width: 100vw !important;
  margin: auto !important;
  /* margin-left: 10vw; */
  height: 110px !important;
  background-color: color-primary !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white;
  z-index: 1601;
}

/deep/ .md-icon-clear:before, .md-icon-fail:before {
  content: '';
}
</style>
